<template>
    <div class="qingwu">
        <div class="admin_main_block">
            <div class="admin_main_block_top">
                <div class="admin_main_block_left">

                    <!--<div><router-link to="/Admin/tgyijfk/form"><el-button type="primary" icon="el-icon-plus">添加</el-button></router-link></div>-->
                    <div><el-input v-model="where.F_VR_XINGM" placeholder="请输入用户姓名"></el-input></div>
                    <div><el-date-picker format="yyyy-MM-dd HH:mm" v-model="where.times" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker></div>
                    <div><el-button icon="el-icon-search" @click="get_store_list()">条件筛选</el-button></div>
                </div>

                <div class="admin_main_block_right">
                    <div><el-button type="danger" icon="el-icon-delete" @click="del(select_id)">批量删除</el-button></div>
                </div>
            </div>

            <div class="admin_table_main">
                <el-table :data="list" @selection-change="handleSelectionChange" >
                    <el-table-column type="selection"></el-table-column>
                    <el-table-column prop="F_IN_ID" label="#" fixed="left" width="70px"></el-table-column>
                    <el-table-column prop="F_VR_NIC" label="用户昵称" width="270px">
                        <template slot-scope="scope">
                            <dl class="table_dl">
                                <dt>
                                    <el-popover placement="left" trigger="click" width="700">
                                        <img :src="scope.row.F_VR_TOUX||require('@/assets/default_pic.png')" width="100%" />
                                        <img
                                                slot="reference"
                                                :src="scope.row.F_VR_TOUX||require('@/assets/default_pic.png')"
                                                :alt="scope.row.F_VR_TOUX"
                                                style="max-height: 50px;max-width: 50px; padding: 5px"
                                        />
                                    </el-popover>
                                </dt>
                                <dd class="table_dl_dd_all_30">昵称： {{ scope.row.F_VR_NIC }}</dd>
                                <dd class="table_dl_dd_all_30">手机号： {{ scope.row.phone }}</dd>
                            </dl>
                        </template>
                    </el-table-column>
                    <el-table-column prop="F_VR_XINGM" label="姓名"  width="170px"></el-table-column>
                    <el-table-column prop="F_VR_WEIXQQ" label="微信/QQ"  ></el-table-column>
                    <el-table-column prop="F_TE_FANKNR" label="反馈内容"  ></el-table-column>

                    <el-table-column label="反馈时间" width="170px">
                        <template slot-scope="scope">
                            <div>{{scope.row.F_IN_FANKSJ|formatDate}}</div>
                        </template>
                    </el-table-column>

                    <!--<el-table-column label="操作" fixed="right" width="220px">
                        <template slot-scope="scope">
                            <el-button icon="el-icon-edit" @click="$router.push({name:'store_form',params:{id:scope.row.id}})">编辑</el-button>
                            &lt;!&ndash;<el-button icon="el-icon-tickets" @click="read_store_info(scope.row)">查看</el-button>&ndash;&gt;
                            &lt;!&ndash; <el-button type="danger" icon="el-icon-delete" @click="del(scope.row.id)">删除</el-button> &ndash;&gt;
                        </template>
                    </el-table-column>-->
                </el-table>
                <div class="admin_table_main_pagination">
                    <el-pagination @current-change="current_change" background layout="prev, pager, next,jumper,total" :total="total_data" :page-size="page_size" :current-page="current_page"></el-pagination>
                </div>
            </div>
        </div>


    </div>
</template>

<script>
    export default {
        components: {},
        props: {},
        data() {
            return {
                list:[],
                total_data:0, // 总条数
                page_size:20,
                current_page:1,
                select_id:'',
                dialogVisible:false,
                info:{},
                store_verify:0,
                where:{
                    times:[],
                    phone:''
                },
            };
        },
        watch: {},
        computed: {},
        methods: {
            handleSelectionChange:function(e){
                let ids = [];
                e.forEach(v => {
                    ids.push(v.F_IN_ID);
                });
                this.select_id = ids.join(',');
            },
            get_store_list:function(){
                let where = {};
                where.page = this.current_page;
                where.params = this.where;
                this.$get(this.$api.getTgYijfkList,where).then(res=>{
                    this.page_size = res.data.per_page;
                    this.total_data = res.data.total;
                    this.list = res.data.data;
                    this.current_page = res.data.current_page;
                })
            },

            // 分页改变
            current_change:function(e){
                this.current_page = e;
                this.get_store_list();
            },
            // 删除处理
            del:function(F_IN_ID){
                let _this = this;
                if(this.$isEmpty(F_IN_ID)){
                    return this.$message.error('请先选择删除的对象');
                }
                this.$post(this.$api.delTgYijfk,{F_IN_ID:F_IN_ID}).then(function(res){
                    if(res.code == 200){
                        _this.get_store_list();
                        return _this.$message.success("删除成功");
                    }else{
                        return _this.$message.error("删除失败！"+res.msg);
                    }
                });
            },


        },
        created() {
            this.get_store_list();
        },
        mounted() {}
    };
</script>
<style lang="scss" scoped>
    .store_list_ul li{
        border-bottom: 1px solid #efefef;
        margin:0 0 30px 0;
        padding-bottom: 15px;
        color:#666;
        h4{
            margin-bottom: 15px;
            color:#333;
        }
    }
</style>